import { User } from 'src/api/base/user/user';
import { Router } from '@angular/router';
import { faDoorOpen, faEdit, faEnvelope, faNoteSticky, faUnlockAlt } from '@fortawesome/free-solid-svg-icons';
import { ProblemService } from './../../../services/problem.service';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Problem } from 'src/Models/problem';
import { DialogService } from 'src/services/dialog.service';
import { UserService } from 'src/api/base/user/user.service';
import { AccessService } from 'src/services/access.service';
import { Organization } from 'src/Models/organization';
import { OrganizationService } from 'src/services/organization.service';
import { Solution } from 'src/Models/solution';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-SearchTableChallenge',
  templateUrl: './SearchTableChallenge.component.html',
  styleUrls: ['./SearchTableChallenge.component.css']
})
export class SearchTableChallengeComponent implements OnInit {

  faUnlockAlt = faDoorOpen;
  faEnroll = faEdit;
problems = new MatTableDataSource<Problem>();
  displayedColumns: string[] = 
  [
  'problemId', 
  'owner',
  'presentation', 
  'area', 
  'theme', 
  'description',
  'trl', 
  'availableBudget',
  'limitDate', 
  'actions'
];
isOrganizationOwner = false;
user!: any;
env = environment.root;

  constructor(private _challenge: ProblemService,
    private _user: UserService,
    private _access: AccessService,
    private _dialog: DialogService,
    private _organization: OrganizationService,
    private router: Router) { }

  ngOnInit() {
    this.user = this._user.obterUsuario();
    this.loadChallenges();
    this.checkIfPersonIsOwner();
  }

  checkIfPersonIsOwner() {
    this.isOrganizationOwner = this._access.checkIfItsOwner();
  }

  organizationName(problem: Problem) {
    this._organization.getById(problem.organizationId).subscribe(result => {
      problem.ownerName = result.name;
      (problem as any).organizationPresentation = result.urlPresentationResume;
      this.problems.data = this.problems.data.map(p => p.problemId === problem.problemId ? problem : p);
    });
  }

  loadChallenges() {
    this._challenge.getAll()
    .subscribe(result =>{
      this.problems = new MatTableDataSource<Problem>(result.filter(x => x.status == 'Ativo'));
      this.problems.data.forEach(element => {
        this.organizationName(element);
      });
    })
  }

  applyFilter(filterValue: any) {
    filterValue = filterValue.target.value;
    filterValue = filterValue.trim();
    filterValue = filterValue.toLocaleLowerCase();
    this.problems.filter = filterValue.trim().toLowerCase();
  }

  archiveProblem(s: any) {
    this.router.navigate(['problem/archive']);
  }

  limitWords(text: string, wordLimit: number): string {
    if (!text) return '';
    const words = text.split(' ');
    return words.length > wordLimit
      ? words.slice(0, wordLimit).join(' ') + '...'
      : text;
  }  

  openDialog(element?: any) {
    const dialogRef = this._dialog.openProposal(element as Problem);
  }

  delete(element: any) {
    this._challenge.deleteProblem(element.problemId).subscribe(
      () => {
        console.log('Problem deleted successfully!');
        this.loadChallenges();
      },
      error => {
        console.log(error);
      }
    )
  }

  getFileIcon(fileName: string): string {
    const extension = fileName?.split('.').pop()?.toLowerCase();
  
    switch (extension) {
      case 'pdf':
        return 'assets/pdf-icon.png';
      case 'doc':
      case 'docx':
        return 'assets/word-icon.png';
      case 'pptx':
        return 'assets/ppt-icon.png';
      default:
        return 'assets/icons/file-icon.png';
    }
  }  

  chargeDetails(data: any){
    //check if the data is a problem or a solution
    if(data.problemId){
      this._dialog.openDialogForTableInfo(data, undefined);
    } else {
      this._dialog.openDialogForTableInfo(undefined, data);
  }
}


}
