import { DialogOwnershipComponent } from './../DialogOwnership/DialogOwnership.component';
import { PersonOrganizationDTO } from './../../../../Models/PersonOrganizationDTO';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserRoleAssignmentModel } from 'src/Models/UserRoleAssignmentModel';
import { UserRoleService } from 'src/services/userRole.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-EditEmployeeDialog',
  templateUrl: './EditEmployeeDialog.component.html',
  styleUrls: ['./EditEmployeeDialog.component.css']
})
export class EditEmployeeDialogComponent implements OnInit {

  personTransition = <PersonOrganizationDTO>{};
  constructor(
    public dialogRef: MatDialogRef<EditEmployeeDialogComponent>,
    private _snackBar: MatSnackBar, // Inject MatSnackBar
    private _dialog: MatDialog, // Inject MatDialog
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _userRole: UserRoleService // Your service to assign roles
  ) {}




ngOnInit(): void {
    this.personTransition = this.data.personOrganization;
}


setUserAsAdmin() {
  let person = this.data.personOrganization;
  this._userRole.getUserByEmail(person.email).subscribe(user => {
    let data: UserRoleAssignmentModel = {
      userId: user.id,
      roleId: '09ebafb8-1232-46c5-9c51-e6231872d23d'
    };
    this._userRole.assignUserRole(data).subscribe(res => {
      this._snackBar.open('User set as Admin successfully', 'Close', { duration: 3000 });
      this.refreshData();
    }, error => {
      this._snackBar.open('Failed to set user as Admin', 'Close', { duration: 3000 });
    });
  });
}


setUserAsOwner() {
  let person = this.data.personOrganization;
  const message = 'Esta ação irá transferir os direitos de Owner para este usuário. Deseja continuar?';

  const dialogRef = this._dialog.open(DialogOwnershipComponent, {
    width: '400px',
    data: { message: message }
  });

  dialogRef.afterClosed().subscribe(result => {
    if (result) {
      this._userRole.getUserByEmail(person.email).subscribe(user => {
        let data = {
          userId: person.personId,
          organizationId: person.organizationId,
          email: user.email
        };
        debugger;
        this._userRole.transferOwnership(data.userId, data.organizationId, data.email).subscribe(res => {
          this._snackBar.open('Ownership transferred successfully', 'Close', { duration: 3000 });
          this.refreshData();
        }, error => {
          this._snackBar.open('Failed to transfer ownership', 'Close', { duration: 3000 });
        });
      });
    } else {
      this._snackBar.open('Ownership transfer cancelled', 'Close', { duration: 3000 });
    }
  });
}


refreshData() {

  window.location.reload();
}


}