import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Injectable()
export class HttpErrorInterceptorService implements HttpInterceptor {

  private lastErrorMessage: string | null = null;

  constructor(private toastr: ToastrService, private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        debugger;
        let errorMessage = 'An unknown error occurred!';
        let isSpecificError = false;

        let customErrorMessage: string | null = null;
  
        // Verificação de erro do lado do cliente
        if (error.error instanceof ErrorEvent) {
          errorMessage = `Client-side error: ${error.error.message}`;
          this.showToast(errorMessage, 'Error');
        } else {
          // Server-side errors
          const statusCode = error.status || 0;
          const serverMessage = error.message || 'No server message';
          
          const backendError = error.error || {}; // Make sure error object exists
          customErrorMessage = backendError.error || backendError.message || backendError || null; // Extract error message

        // Ignora erro 404 específico ao entrar no Dashboard e user não associação a empresa ativa
        if (statusCode === 404 && customErrorMessage?.includes('No organizations actives found')) {
          console.warn('Erro 404 ignorado: Nenhuma organização ativa encontrada.');
          return throwError(customErrorMessage); // Ignora o toast e apenas retorna o erro
        }

        // Tratamento de erro específico de CPF duplicado
         if (typeof customErrorMessage === 'string' && 
          customErrorMessage === 'Este CPF já está vinculado a outro usuário.') {
          this.showToast('CPF já cadastrado', 'Erro');
          isSpecificError = true;
        }

        // Tratamento de erro específico de usuário já possui a role do Owner
        if (typeof customErrorMessage === 'string' && 
          customErrorMessage === 'Não foi possível atribuir a role Owner ao usuário.') {
          this.showToast('Usuário já possui role Owner', 'Erro');
          isSpecificError = true;
        }

          // Condição para suprimir notificação de erro específico
          if (typeof customErrorMessage === 'string' && customErrorMessage.includes('No organizations found for person with ID')) {
            // Não exibe notificação para este erro específico
            console.warn('Organization not found - suppressing notification.');
            return throwError(customErrorMessage); // Retorna o erro, mas sem notificação
          }
          
          // Use a specific message if available, otherwise fallback
          errorMessage = customErrorMessage ? customErrorMessage : `Erro no servidor: ${statusCode} - ${serverMessage}`;
          if(backendError && backendError.errors[0].toLowerCase().includes('organization') &&
          backendError.toLowerCase().includes('already exists')){
            this.showToast('Organização já cadastrada', 'Erro');
            isSpecificError = true;
          }

          if(backendError && backendError.errors[0].toLowerCase().includes('Este CPF já está vinculado a outro usuário.')){
            this.showToast('CPF já cadastrado', 'Erro');
            isSpecificError = true;
          }

          if(backendError && backendError.errors[0].toLowerCase().includes('vá na sua caixa de e-mail e confirme-o')){
            isSpecificError = true;
            return throwError(backendError.errors[0]);
          }

          if(backendError && backendError.errors[0].toLowerCase().includes('usuário ou senha incorretos')){
            isSpecificError = true;
            return throwError(backendError.errors[0]);
          }
          
          if (!isSpecificError) {
            switch (statusCode) {
              case 401:
                this.showToast('Unauthorized', 'Error 401');
                this.router.navigate(['/login']);
                break;
              case 404:
                this.showToast('Resource not found', 'Error 404');
                this.router.navigate(['/not-found']);
                break;
              case 409:
                this.showToast(backendError, 'CNPJ já está cadastrado');
                break;
              case 500:
                
                this.showToast('Internal server error', 'Error 500');
                break;
              case 503:
                this.showToast('Servidor temporariamente fora', 'Error 503');
                break;
              default:
                // For any other status code or unexpected structure
                this.showToast(errorMessage, 'Error');
                break;
            }
          }
        }

        console.error('Full error details:', error);

        return throwError(errorMessage);
      })
    );
  }

  // Utility function to show toast and filter duplicates
  private showToast(message: string, title: string) {
    if (this.lastErrorMessage !== message) {
      this.toastr.error(message, title, {
        timeOut: 3000, // Adjust as needed
        closeButton: true,
        positionClass: 'toast-top-right', // Adjust position
      });
      this.lastErrorMessage = message;
    }
  }
}
