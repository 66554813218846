import { Injectable } from '@angular/core';
import { Notyf } from 'notyf';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private notyf = new Notyf({
    duration: 3000,
    types: [
      {
        type: 'error',
        background: 'red',
        message: 'white', 
      },
      {
        type: 'success',
        background: 'green', 
        message: 'white'
      }
    ]
  });

  constructor() { }

  public success(message: string): void {
    this.notyf.success(message);
  }

  public error(message: string): void {
    this.notyf.open({
      type: 'error',
      message: message,
    });
  }
  

  // Add other methods as needed for different types of notifications
}

