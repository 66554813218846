import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { FileUploadService } from 'src/services/fileUpload.service';

@Component({
  selector: 'app-file-upload',
  templateUrl: './FileUpload.component.html',
  styleUrls: ['./FileUpload.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploadComponent),
      multi: true
    }
  ]
})
export class FileUploadComponent implements ControlValueAccessor {
  // The internal data model
  private file: File | null = null;

  @Output() fileNameChange = new EventEmitter<string>();
  @Input() allowedTypes: string[] = []; // Example: ['image/*', 'application/pdf']
  @Input() fileUrl: string | null = null;
  selectedFileName: string = 'Nenhum arquivo escolhido';
  onChange = (file: File | null) => {};
  onTouched = () => {};

  constructor(private fileUploadService: FileUploadService) { }

  ngOnChanges() {
    if (this.fileUrl) {
      const parts = this.fileUrl.split('/');
      this.selectedFileName = parts[parts.length - 1]; // Pega apenas o nome do arquivo
    }
  }  

  writeValue(file: File | null): void {
    this.file = file;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // New method to handle file selection and upload
  handleFileChange(event: Event) {
    const element = event.target as HTMLInputElement;
    const files = element.files;
    if (files && files.length > 0) {
      const file = files.item(0);
      if (file && this.allowedTypes.some(type => file.type.match(type))) {
        this.selectedFileName = file.name;
        this.file = file;
        this.onChange(file); // Notify Angular form control about the change
        this.onTouched(); // Mark the control as 'touched'
        this.fileNameChange.emit(file.name); // Notify parent component about the file name change

        // Now, upload the file using your FileUploadService
        this.fileUploadService.uploadFile(file).subscribe({
          next: (uploadEvent: any) => {
            if (uploadEvent.body?.url) {
              console.log('Upload successful', uploadEvent.body.url);
              this.fileNameChange.emit(uploadEvent.body.url); // <<< agora envia o caminho salvo
            }
          },
          error: (error) => {
            // Handle upload error
            console.error('Upload failed', error);
          }
        });
      } else {
        console.error('No file selected');
      }
    } else {
      console.error('No files object or files are empty');
    }
  }
}
